import React, { useContext, useEffect } from "react";
import { useQuery } from "@apollo/client/react/hooks/useQuery.js";
import Content from "../../i18n/content.js";
import { container } from "../styles/common.css.js";
import MoonView from "./MoonView.js";
import CalendarGrid from "./CalendarGrid.js";
import MonthSwitcher from "./MonthSwitcher.js";
import Spinner from "../Spinner.js";
import Params from "./Params.js";
import MoonCalendarContexts from "./MoonCalendarContexts.js";
import {
    GET_MOON_CALENDAR_CONTENT,
    GetMoonCalendarContentData,
    GetMoonCalendarContentVariables,
    MoonCalendarContent,
} from "../../graphql/queries/moon-calendar.js";
import ErrorMsg from "../common/errors/ErrorMsg.js";
import PickedDate from "./PickedDate.js";
import GeoLocationPicker from "./GeoLocationPicker.js";
import MoonPhase from "./MoonPhase.js";
import { textStyle } from "../news/NewsPage.css.js";
import MarkdownRenderer from "../common/MarkdownRenderer.js";
import { Image } from "../../graphql/queries/gallery.js";
import MetaTags from "../MetaTags.js";
import { h1Style, headerContainer } from "./common.css.js";
import { geoLocationOptionToCityGeoLocation } from "./utils.js";
import HtmlFaqStructuredData from "../helmet/HtmlFaqStructuredData.js";
import { PromoItem } from "../../graphql/queries/_common.js";
import { PromoAppSetterContext } from "../layout/PromoAppContextProvider.js";
import { App } from "../../graphql/queries/apps.js";
import ActionsTrigger from "../layout/ActionsTrigger.js";
import GoogleAdSenseAdUnit from "../common/GoogleAdSenseAdUnit.js";
import NotFoundErrorMsg from "../common/errors/NotFoundErrorMsg.js";
import ScrollTracker from "../common/ScrollTracker.js";

export const MoonCalendarContentContext =
    React.createContext<MoonCalendarContent>({} as MoonCalendarContent);

const MoonCalendar: React.FC = () => {
    const { langCode } = useContext(Content);
    const pickPromoApp = useContext(PromoAppSetterContext);

    const { data, loading, error } = useQuery<
        GetMoonCalendarContentData<MoonCalendarContent>,
        GetMoonCalendarContentVariables
    >(GET_MOON_CALENDAR_CONTENT, {
        variables: {
            id: "sw-site-moon-calendar",
            geoLocationId: "5026",
            langCode,
        },
    });

    useEffect(() => {
        if (data?.staticContent?.promoItems) {
            const promoApps = data.staticContent.promoItems.filter(
                (i): i is App => !!i && i.__typename === "App",
            );

            pickPromoApp({
                type: "pick",
                apps: promoApps,
            });
        }
    }, [data, pickPromoApp]);

    if (error) {
        return <ErrorMsg error={error} />;
    }

    if (loading) {
        return <Spinner />;
    }

    if (data && data.staticContent && data.defaultGeoLocation) {
        const {
            staticContent: {
                contentLangs: langCodes,
                content: {
                    title,
                    metaTitle,
                    metaDescription,
                    _text,
                    tocHeader,
                },
                faq,
                mediaItems,
                promoItems,
            },
            defaultGeoLocation,
        } = data;
        const cityGeoLocation =
            geoLocationOptionToCityGeoLocation(defaultGeoLocation);
        return (
            <MoonCalendarContentContext.Provider
                value={data.staticContent.content}
            >
                <MoonCalendarContexts defaultGeoLocation={cityGeoLocation}>
                    <MetaTags
                        currentLang={langCode}
                        langs={langCodes}
                        path={"/moon-calendar"}
                        title={metaTitle}
                        description={metaDescription}
                        mediaItem={mediaItems && mediaItems[0]}
                        largeImagePreview
                    />
                    {faq && <HtmlFaqStructuredData faq={faq} />}
                    <main className={container.flex750}>
                        <div className={headerContainer}>
                            <h1 className={h1Style}>{title}</h1>
                            <GeoLocationPicker
                                defaultGeoLocation={cityGeoLocation}
                            />
                        </div>
                        <PickedDate />
                        <MoonView />
                        <Params />
                        <MoonPhase />
                        <MonthSwitcher />
                        <CalendarGrid />
                        <ActionsTrigger position="20vh" />
                        <MarkdownRenderer
                            className={textStyle}
                            source={_text}
                            tocHeader={tocHeader}
                            images={
                                mediaItems
                                    ? mediaItems.filter(
                                          (m): m is Image =>
                                              !!m && m.__typename === "Image",
                                      )
                                    : undefined
                            }
                            promoItems={
                                promoItems?.filter(
                                    (p): p is PromoItem =>
                                        !!p &&
                                        (p.__typename === "Quiz" ||
                                            p.__typename === "Infographics"),
                                ) || undefined
                            }
                            adBanner={
                                <div>
                                    <GoogleAdSenseAdUnit
                                        adSlot="3250339085"
                                        itemId="moon-calendar"
                                        key="moon-calendar"
                                        style={{
                                            display: "block",
                                            textAlign: "center",
                                        }}
                                        adFormat="fluid"
                                        fullLayout="in-article"
                                    />
                                </div>
                            }
                        />
                        <ScrollTracker
                            position="50%"
                            eventName={`moon_calendar_page_visible_50`}
                        />
                        <ScrollTracker
                            position="95%"
                            eventName={`moon_calendar_page_visible_95`}
                        />
                    </main>
                </MoonCalendarContexts>
            </MoonCalendarContentContext.Provider>
        );
    }

    return <NotFoundErrorMsg />;
};

export default MoonCalendar;
